<template>
  <img
    class="block my-0 mx-auto xl:w-auto w-full object-contain max-h-[500px] max-w-[100%]"
    :src="optimisedSource"
    :alt="decodedAlt"
  />
  <!-- Need to use this as figure tags are not allowed in p tags (parent) -->
  <span v-if="decodedTitle" class="block figcaption">{{ decodedTitle }}</span>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { safeDecodeAttribute } from '@/utils/attributes';

const props = defineProps({
  src: { type: String, required: true },
  alt: String,
  title: String,
});
const decodedAlt = computed(() => safeDecodeAttribute(props.alt || ''));

const decodedTitle = computed(() => {
  const title = safeDecodeAttribute(props.title || '');
  return title === 'null' ? decodedAlt : title;
});

const optimisedSource = computed(() =>
  props.src?.startsWith('https://a.storyblok.com') ? `${props.src}/m/fit-in` : props.src
);
</script>
